import { render, staticRenderFns } from "./ArchiveCategory.vue?vue&type=template&id=6965c078&scoped=true&"
import script from "./ArchiveCategory.vue?vue&type=script&lang=js&"
export * from "./ArchiveCategory.vue?vue&type=script&lang=js&"
import style0 from "./ArchiveCategory.vue?vue&type=style&index=0&id=6965c078&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6965c078",
  null
  
)

export default component.exports