<template>
  <div class="archive-category">
    <div class="wrapper">
      <div class="head">
        <div class="title">
          <span>분류 {{ modalParams.edit ? "수정" : "추가" }}</span>
        </div>
      </div>
      <div class="body">
        <div class="section">
          <label :for="`${component.name}Title`">
            <span class="color-purple">* </span>
            <span>분류 이름</span>
          </label>
          <input type="text" class="form-control" :id="`${component.name}Title`" v-model="state.args.title" placeholder="ex) 오마이컴퍼니 크라우드펀딩 모집">
        </div>
        <div class="section">
          <label :for="`${component.name}ManagerTel`">담당자 직통전화</label>
          <Number :value.sync="state.args.managerTel" :noComma="true" :allowZero="true" :maxlength="11" :id="`${component.name}ManagerTel`" placeholder="ex) 023882556" className="border-focus-purple"/>
        </div>
        <div class="section check">
          <span>제약 조건 여부</span>
          <label class="switch" :for="`${component.name}ConstraintFlag`" @click="ChangeConstraintFlag()">
            <input type="checkbox" :checked="state.args.constraintFlag === 'Y'">
            <span class="slider round"></span>
          </label>
        </div>
        <div class="section row" v-if="state.args.constraintFlag === 'Y'">
          <div class="col-6">
            <label :for="`${component.name}ParentLabel`">
              <span class="color-purple">* </span>
              <span>부모 제약 카테고리</span>
            </label>
            <input type="text" v-model="state.args.constraintParentLabel" :id="`${component.name}ParentLabel`" class="form-control" placeholder="ex) 신청 유형"/>
          </div>
          <div class="col-6">
            <label :for="`${component.name}ChildLabel`">
              <span class="color-purple">* </span>
              <span>자식 제약 카테고리</span>
            </label>
            <input type="text" v-model="state.args.constraintChildLabel" :id="`${component.name}ChildLabel`" class="form-control" placeholder="ex) 시간"/>
          </div>
        </div>
      </div>
    </div>
    <div class="actions">
      <button class="btn btn-purple" @click="submit()">저장</button>
      <button class="btn btn-dark" @click="remove()">삭제</button>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import http from "@/scripts/http";
import Number from "@/components/Number";

function Component(initialize) {
  this.name = "modalArchiveCategory";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Number},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "sm"});

      if (modalParams?.edit) {
        state.args.constraintFlag = modalParams.category.constraintFlag;
        state.args.title = modalParams.category.title;
        state.args.constraintParentLabel = modalParams.category.constraintParentLabel;
        state.args.constraintChildLabel = modalParams.category.constraintChildLabel;
        state.args.managerTel = modalParams.category.managerTel;
      }
    });

    const state = reactive({
      args: {
        constraintFlag: "N",
        title: "",
        constraintParentLabel: "",
        constraintChildLabel: "",
        managerTel: "",
      }
    });
    const modalParams = store.getters.modalParams(component);

    const ChangeConstraintFlag = () => {
      return state.args.constraintFlag === "Y" ? state.args.constraintFlag = "N" : state.args.constraintFlag = "Y";
    };

    const remove = () => {
      store.commit("confirm", {
        message: "해당 분류를 삭제하시겠습니까?",
        allow() {
          http.delete(`/api/admin/archive/categories/${modalParams.category.id}`).then(() => {
            store.commit("setSwingMessage", "분류를 삭제했습니다.");
            store.commit("closeModal", {
              name: component.name,
              onClose(modal) {
                store.dispatch("callback", {modal});
              }
            });
          });
        }
      });
    };

    const submit = async () => {
      if (!isValid()) {
        return;
      }

      const args = {};
      for (let i in state.args) {
        state.args[i] && (args[i] = state.args[i]);
      }

      args.ord = modalParams.category.ord;

      if (modalParams.edit) {
        await http.put(`/api/admin/archive/categories/${modalParams.category.id}`, args);
      } else {
        await http.post("/api/admin/archive/categories", args);
      }

      store.commit("setSwingMessage", "분류를 저장했습니다.");
      store.commit("closeModal", {
        name: component.name,
        onClose(modal) {
          store.dispatch("callback", {modal});
        }
      });
    };

    const isValid = () => {
      const warn = (title, id) => {
        store.commit("setSwingMessage", `${title} 작성해주세요`);
        return document.querySelector(`#${component.name + id}`)?.focus();
      };

      if (!state.args.title) {
        warn("분류 이름을", "Title");
        return false;
      } else if (state.args.constraintFlag === "Y" && !state.args.constraintParentLabel) {
        warn("부모 제약 카테고리를", "ParentLabel");
        return false;
      } else if (state.args.constraintFlag === "Y" && !state.args.constraintChildLabel) {
        warn("자식 제약 카테고리를", "ChildLabel");
        return false;
      }

      return true;
    };

    return {component, state, modalParams, ChangeConstraintFlag, submit, remove};
  }
});
</script>

<style lang="scss" scoped>
.archive-category {
  > .wrapper {
    padding: $px25;
    background: #fff;

    .head {
      margin-bottom: $px20;

      .title {
        font-size: $px18;
      }
    }

    .body {
      font-size: $px14;

      .section {
        .form-control {
          height: $formHeight;
        }

        &.check {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: $px10;
          border: $px1 solid $colorBorder;
          border-radius: $px4;
          height: $formHeight;

          .switch {
            position: relative;
            display: inline-block;
            width: $px50;
            height: $px21;
            margin-bottom: 0;

            > input {
              opacity: 0;
              width: 0;
              height: 0;

              &:checked + .slider {
                background-color: $colorPurple;

                &::before {
                  transform: translate($px29, -50%);
                }
              }

              &:focus + .slider {
                box-shadow: 0 0 $px1 #2196F3;
              }
            }

            > .slider {
              cursor: pointer;
              background-color: #ccc;
              display: inline-block;
              position: relative;
              width: 100%;
              height: 100%;
              transition: .4s;

              &.round {
                border-radius: $px50;

                &::before {
                  border-radius: 50%;
                }
              }

              &::before {
                position: absolute;
                content: "";
                height: $px13;
                width: $px13;
                left: $px4;
                top: 50%;
                transform: translateY(-50%);
                background-color: #fff;
                transition: 0.18s;
              }
            }
          }
        }

        &:not(:last-of-type) {
          margin-bottom: $px20;
        }
      }
    }
  }

  > .actions {
    display: flex;

    .btn {
      margin-right: 0;
      flex-grow: 1;
      padding: $px15;
      height: $px60;
      border-radius: 0;
      font-size: $px13;
    }
  }
}
</style>